import 'core-js/es/map' 
import 'core-js/es/set' 

import React from 'react' 
import ReactDOM from 'react-dom' 

import './_scss/index.scss'

import App from './App'
import ProductDataTable from './ProductDataTable'

initCache()

if (window.location.search.indexOf('hinnasto') >= 0) {
  ReactDOM.render(<ProductDataTable />, document.getElementById('root'))
}
else {
  ReactDOM.render(<App />, document.getElementById('root'))
}

window.svg4everybody()

function initCache() {
  const version = require('../package.json').version.split('.'),
        itemsToRemove = ['order']
  let reload = false,
      cacheVersion = window.localStorage.getItem('version')
 
  cacheVersion = cacheVersion ? cacheVersion.split('.') : [0, 0, 0]
  if (cacheVersion[0] < version[0]) {
    window.localStorage.clear()
    reload = true
  }
  else if (cacheVersion[1] < version[1]) {
    itemsToRemove.forEach((item) => window.localStorage.removeItem(item))
    reload = true
  }
  window.localStorage.setItem('version', version.join('.'))

  if (reload) window.location = '/'
}