import React, { Component } from 'react'

class ExpandableImage extends Component {
  render() {
    if (! this.props.image || this.props.image.length <= 0) return null

    return (
      <div
        onClick={this.props.onClick}
        className="image-crop">
        <img className={this.props.className} src={this.props.image} alt="" />
      </div>
    )
  }
}

export default ExpandableImage