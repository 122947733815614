import React from 'react'

import OrderManager from '../OrderManager'
import ProductManager from '../ProductManager'

import Icon from '../icons/Icon'

class ProductAndSizeSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { product: null, priceList: null }

    this.reset = this.reset.bind(this)
    this.select = this.select.bind(this)
    this.switchProduct = this.switchProduct.bind(this)
  }

  get selection() {
    return { product: this._productInput.value, size: this._sizeInput.value }
  }

  reset() {
    this._productInput.value = ""
    this._sizeInput.value = ""
    this.setState({ product: null, priceList: null })
  }

  select(e) {
    OrderManager.addProduct(this._productInput.value, this._sizeInput.value)
    this.props.onSelect()
    this.reset()
    this._toggleAddProductFormButton.focus()
  }

  switchProduct(e) {
    const product = ProductManager.findProduct(e.target.value)
    this.setState({ product: product, priceList: ProductManager.findPriceSet(product) })
    window.setTimeout(() => this._sizeInput.focus(), 10)
  }

  render() {
    return (
      <div>
        <div className={`add-product collapse ${this.props.show ? '' : 'show'}`} id="toggleAddProductForm">
          <button ref={(me) => this._toggleAddProductFormButton = me} type="button" className="btn link-button w-100" data-toggle="collapse" aria-controls="toggleAddProductForm addProductForm" data-target=".add-product" onClick={(e) => this._productInput.focus()}>
            <Icon icon="add" />
            {this.props.showLabel}
          </button>
        </div>
        <div className={`add-product collapse ${this.props.show ? 'show' : ''}`} id="addProductForm">
          <select className="custom-select form-control mb-2" defaultValue="" name="orderProduct" onChange={this.switchProduct} ref={(me) => this._productInput = me}>
            <option value="" disabled={true}>{this.props.productLabel}</option>
            {this.props.productLists.map((list) =>
              <optgroup key={list.title} label={list.title}>
                {list.products.map((product) =>
                  <option key={product.id} value={product.id} dangerouslySetInnerHTML={{ __html: product.name}}></option>
                )}
              </optgroup>
            )}
          </select>

          <div className="input-group mb-2">    
            <select className="custom-select form-control" name="orderSize" disabled={this.state.priceList === null} onChange={() => this._addProductButton.focus()} ref={(me) => this._sizeInput = me}>
              {this.state.priceList != null && this.state.priceList.map((price) =>
                <option key={price.size} value={price.size}>{price.price}€ - {ProductManager.findSizeDescription(price.size)}</option>
              )}
            </select>  
            <div className="input-group-append">
              <button type="button" className="btn btn-outline-secondary" data-toggle="collapse" aria-controls="toggleAddProductForm addProductForm" data-target=".add-product" name="orderAddProduct" disabled={this.state.priceList == null} onClick={this.select} ref={(me) => this._addProductButton = me}>{this.props.addProductLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductAndSizeSelect