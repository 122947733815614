import React, { Component } from 'react'

import Icon from './Icon'

class Divider extends Component {
  render() {
    return (
      <div className={`divider ${this.props.mobileOnly ? 'mobile' : ''}`}>
        <Icon icon="dividerLeft" />
        <Icon icon="cake" />
        <Icon icon="dividerRight" />
      </div>
    )
  }
}

export default Divider