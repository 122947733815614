import React from 'react'

import Icon from '../icons/Icon'

class WaitModal extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="modal fade waiting" data-backdrop="static" data-keyboard="false" tabIndex="-1">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <Icon icon="cake" />
            </div>
        </div>
      </div>
    )
  }
}

export default WaitModal