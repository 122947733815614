import React, { Component } from 'react'

class Contact extends Component {
  render() {
    return (
      <div className="container">
      <address>
        <strong>{this.props.contact.name}</strong><br />
        {this.props.contact.address}<br />
        <a href={`tel:+358${this.props.contact.phone.replace(/[()\s}]/g, '').substr(1)}`}>
          {this.props.contact.phone}
        </a><br />
        <a href={`mailto:${this.props.contact.email}`}>{this.props.contact.email.replace(/@/g, ' (at) ')}</a>
      </address>
      <address className="oiva">
        <a href={this.props.contact.oiva.url}>{this.props.contact.oiva.text}</a>
      </address>
    </div>
    )
}
}

export default Contact