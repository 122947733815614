import React, { Component } from 'react'

import Icon from './Icon'
import MultipleIcons from './MultipleIcons'

class SizeBasedIcon extends Component {
  render() {
    switch (this.props.type) {
      case "X":
      case "S":
      case "M":
      case "L":
        return (
          <div className={`icon-size-${this.props.type}`}>
            <Icon icon={this.props.icon} />
          </div>)
      default:  
        return (
          <MultipleIcons count={this.props.count} icon={this.props.icon} />
        )
    }
  }
}

export default SizeBasedIcon