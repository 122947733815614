import React from 'react'
import Icon from '../icons/Icon'

class LocationSelect extends React.Component {
  constructor(props) {
    super(props)
    
    this.select = this.select.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    if (this.props.value === null) {
      this.props.onChange({ target: { name: 'pickupLocation', value: this.props.locations[0] } })
    }
  }

  select(location) {
    let selected = (! this.props.value || this.props.value.id !== location.id) ? location : null
    if (this.props.next && selected && this.props.next) document.getElementsByName(this.props.next)[0].focus()
    this.props.onChange({ target: { name: 'pickupLocation', value: selected } })
  }

  handleKeyPress(e, location) {
    switch(e.key) {
      case "Enter":
      case " ":
        this.select(location)
        break
      case "ArrowDown":
        if (e.target.nextElementSibling) e.target.nextElementSibling.focus()
        break
      case "ArrowUp":
        if (e.target.previousElementSibling) e.target.previousElementSibling.focus()
        break
      default:
    }
  }

  render() {
    return (
      <div className={`location-select ${this.props.className} ${this.props.value ? 'one-selected' : ''}`}>
        <h4>{this.props.title}</h4>
        {this.props.locations.map((location) => 
          <div
            key={location.id}
            tabIndex="0"
            className={`form-control mb-2 ${this.props.value && this.props.value.id === location.id ? 'selected' : ''}`}
            onClick={(e) => this.select(location)} 
            onKeyUp={(e) => this.handleKeyPress(e, location)} >
            {location.mapLink >= 0 &&
              <a
                className="link-button float-right"
                href={this.props.mapLinks[location.mapLink]}
                target="_blank"
                onClick={(e) => e.stopPropagation()}>
                <Icon icon="map" />
              </a>
            }
            <div>
              {location.name}
              { (parseInt(location.cost, 10) > 0) && 
                <b> +{location.cost} €</b>
              }
            </div>
            { (! this.props.value || this.props.value.id === location.id) &&
              <div className="sub-text">{location.pickupTimes}</div>
            }
          </div>
        )}
      </div>
    )
  }
}

export default LocationSelect