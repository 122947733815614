import React, { Component } from 'react'

import Icon from '../icons/Icon'

class Logo extends Component {
  render() {
    return (
      <a role="button" className={this.props.className} onClick={this.props.onClick}>
        <Icon icon="logo" className="logo-image" />
      </a>
    )
  }
}

export default Logo