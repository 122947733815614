import React, { Component } from 'react'

import OrderManager from '../OrderManager'
import ProductManager from '../ProductManager'

import SizeBasedIcon from '../icons/SizeBasedIcon'

class Price extends Component {
  constructor(props) {
    super(props)
    this.addProduct = this.addProduct.bind(this)
  }

  addProduct(e) {
    const found = OrderManager.addProduct(this.props.product, this.props.price.size).length > 0
    if (!found) return

    this.button.classList.add('adding')
    this.button.blur()
    window.setTimeout(() => this.button.classList.remove('adding'), 600)
    
    this.props.onSelectedProductsUpdate()

    e.preventDefault()
  }

  render() {
    const size = this.props.price.size,
          description = ProductManager.findSizeDescription(size),
          sizeType = ProductManager.parseSizeType(size),
          icon = ProductManager.parseIcon(size),
          count = ProductManager.parseCount(size)
          
    return (
      <span className="price">
        <button ref={(me) => this.button = me} tabIndex={0} className="btn btn-outline-secondary" onClick={this.addProduct}>
          <SizeBasedIcon type={sizeType} icon={icon} count={count} />
          <span className="text">{this.props.price.price.toLocaleString()} €</span>          
        </button>
        <p className="text">{description}</p>
      </span>
    )
  }
}

export default Price