import React from 'react'

import Icon from '../icons/Icon'

class OrderButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedCount: 0, pulse: false }
  }
  componentDidUpdate() {
    if (this.state.selectedCount !== this.props.selectedCount) {
      this.setState({ pulse: true, selectedCount: this.props.selectedCount })
      window.setTimeout(() => this.setState({ pulse: false }), 1000)
    }
  }
  render() {
    return (
      <button className="btn btn-default" onClick={this.props.onClick}>
        {this.props.title}
        {(this.props.selectedCount > 0) &&
          <span className={`badge ${this.state.pulse ? "pulse" : ""}`}>
            <Icon icon="circle" />
            {this.props.selectedCount}
          </span>
        }
        
      </button>
    )
  }
}

export default OrderButton