import React, { Component } from 'react'

import Divider from '../icons/Divider'

class BackgroundView extends Component {
  render() {
    const imageSet = this.props.shared.imageSets[this.props.view.data.imageSet]

    return (
      <div className="container background">
        <div className="clearfix">
          <div className="secondary-image">
            {imageSet.secondaryImage.map((image) => 
              <img key={image} src={image} alt="" />
            )}
          </div>
          <div className="main-image">
            <img src={imageSet.mainImage} alt="Bettina Winquist" />
          </div>
        </div>
        <h1>{this.props.view.data.title}</h1>
        {this.props.view.data.body.map((paragraph, index) =>
          <p key={index}>{paragraph}</p>
        )}
        <Divider />
      </div>
    )
  }
}

export default BackgroundView