import content from './data/content'

class ProductManager {
  _key = 'SelectedProducts'
  _pidKey = 'kaka:'

  constructor() {
   if (! ProductManager.instance) {
    this._productLists = []
    this._sizes = {}
    this._images = content.shared.productImages.slice()
    this._priceSets = content.shared.priceSets.slice()
    this._ingredients = []

    ProductManager.instance = this
   }

   return ProductManager.instance
  }

  get productLists() {
    return this._productLists.slice()
  }

  get pidKey() {
    return this._pidKey
  }

  init(content) {
    this._productLists.splice(0)
    let productViews = content.views.filter((v) => v.type === 'products')
    productViews.forEach(productView => {
      this._productLists.push({
        viewId: productView.id,
        title: productView.title,
        products: productView.products
      })
    })

    for (let prop in content.sizes) {
      if (content.sizes.hasOwnProperty(prop)) {
        this._sizes[prop] = content.sizes[prop]
      }
    }

    this._ingredients.splice(0)
    content.ingredients.map((ingredient) => this._ingredients.push(ingredient))

    return this
  }

  buildIngredientList(product) {
    var ingredientListing = '',
        delimiter = ''
    for (var i = 0; i < product.ingredients.length; i++) {
      ingredientListing += delimiter
      ingredientListing += this._ingredients[product.ingredients[i]]
      delimiter = ', '
    }
    return (ingredientListing.substring(0, 1).toUpperCase() + ingredientListing.substring(1))
  }

  findImage(product) {
    return this._images[product.image]
  }

  findProduct(id) {
    let match = { id: parseInt(id, 10), product: null }
    this._productLists.some(list => 
      list.products.some(this._findProduct, match)
    )
    return match.product
  }

  _findProduct(product) {
    if (product.id === this.id) {
      this.product = product
      return true
    }
    return false
  }

  findProductWithHash(hash) {
    if (! hash) return null
    if (hash.indexOf(this.pidKey) < 0) return null
    let pid = hash.substring(hash.indexOf(this.pidKey) + this.pidKey.length)
    return this.findProduct(pid)
  }

  findPriceSet(product) {
    return this._priceSets[product.prices]
  }

  findSize(sizeType) {
    return this._sizes[sizeType]
  }

  findSizeDescription(sizeId) {
    const count = this.parseCount(sizeId),
          type = this.parseSizeType(sizeId)
    return (count > 1) 
            ? this._sizes[type].multiple.replace(/%%COUNT%%/g, count.toString()) 
            : this._sizes[type].single
  }

  findViewForProduct(product, views) {
    let viewId = 0
    this._productLists.some(list => {
      if (list.products.indexOf(product) >= 0) {
        viewId = list.viewId
        return true
      }
      return false
    })
    return views.filter((v) => v.id === viewId)[0]
  }

  parseCount(sizeId) {
    if (sizeId.length > 1) return parseInt(sizeId.substr(1), 10)
    return 1
  }

  parseIcon(sizeId) {
    switch(this.parseSizeType(sizeId)) {
      case "A":
      case "B": 
        return "box"
      case "p": 
      case "P": 
        return "pie"
      default: 
        return "cake"
    }
  }

  parseSizeType(sizeId) {
    return sizeId.substr(0, 1)
  }
}

const instance = new ProductManager()
Object.freeze(instance)

export default instance