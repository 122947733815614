import React, { Component } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import MainView from './MainView'
import ProductView from './ProductView'
import BackgroundView from './BackgroudView'
import OrderView from './OrderView'

class ViewController extends Component {
  render() {
    let viewComponent = null
    
    switch(this.props.view.type) {
      case 'products':
        viewComponent = ProductView
        break 
      case 'background':
        viewComponent = BackgroundView
        break 
      case 'order':
        viewComponent = OrderView
        break 
      default:
        viewComponent = MainView
    }
    return (
      <TransitionGroup>
        <CSSTransition
          key={`${viewComponent.key}-transition`}
          classNames="fade"
          timeout={{ appear: 500, enter: 500, exit: 300 }}>
          {React.createElement(viewComponent, {
            key: `${this.props.view.type}view`,
            ...this.props
          })}
        </CSSTransition>
      </TransitionGroup>
    ) 
  }
}

export default ViewController