import React, { Component } from 'react'

import OrderManager from '../OrderManager'
import ProductManager from '../ProductManager'

import Divider from '../icons/Divider'
import LocationSelect from '../components/LocationSelect'
import Modal from '../components/Modal'
import ProductAndSizeSelect from '../components/ProductAndSizeSelect'
import WaitModal from '../components/WaitModal'
import SelectedProducts from '../components/SelectedProducts'

class OrderView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogData: {},
      order: OrderManager.order
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
    this.startSending = this.startSending.bind(this)
    this.sendingFailed = this.sendingFailed.bind(this)
    this.sendingSucceeded = this.sendingSucceeded.bind(this)
  }

  handleInputChange(e) {
    const prop = e.target.name.split('.')
    if (prop.length > 1) OrderManager.order[prop[0]][prop[1]] = e.target.value
    else OrderManager.order[prop[0]] = e.target.value
    OrderManager.toCache()
    this.setState({ order: OrderManager.order })
  }

  next(e, name) {
    if (e.key === "Enter") {
      document.getElementsByName(name)[0].focus()
      e.preventDefault()
    }
  }

  removeProduct(selected) {
    OrderManager.removeProduct(selected)
    this.props.onSelectedProductsUpdate()
  }

  startSending(event) {
    event.preventDefault()
    if (! OrderManager.order.pickupLocation || OrderManager.order.pickupLocation.name.length <= 0) {
      document.querySelector('.location-select>div.form-control').focus()
      window.setTimeout(() => 
        window.$('.location-select')
          .popover({
            'content': this.props.view.data.form.pickupLocationLabel,
            'trigger': 'manual',
            'placement': 'top',
            'container': 'div.container.order' 
          })
          .popover('show'), 100)
      window.setTimeout(() => window.$('.location-select').popover('hide'), 2000)
      return
    }
    if (OrderManager.order.products.length <= 0) {
      let selected = this._productSelector.selection
      if (selected.product) {
        if (OrderManager.addProduct(selected.product, selected.size).length <= 0) {
          return
        }
      }
      else {
        return
      }
    }
    window.$('#waitModal').modal('show')
  }

  sendingFailed(response, status, ex) {
    var dialogData = this.props.view.data.form.failedDialog
    dialogData.centerBody = true
    if (response.readyState === 0) {
      dialogData.body[1] = dialogData.noNetwork
    }
    else {
      dialogData.body[1] = `${dialogData.serverError} (${ex})`
      dialogData.dynamicBody = OrderManager.orderAsHtmlRows(this.props.view.data.form)
    }
    
    this.setState({ dialogData: dialogData })

    window.$('#waitModal').modal('hide')
  }

  sendingSucceeded() {
    var dialogData = this.props.view.data.form.successDialog
    dialogData.dynamicBody = OrderManager.orderAsHtmlRows(this.props.view.data.form)
    OrderManager.clear()
    this._productSelector.reset()
    
    this.setState( { dialogData: dialogData, order: OrderManager.order })
    
    window.$('#waitModal').modal('hide')

    this.props.onSelectedProductsUpdate()
  }

  componentDidMount() {
    window.$('#waitModal')
      .on('shown.bs.modal', () => {
        OrderManager.send(this.props.view.data.emailTemplate, this.props.view.data.form)
          .done(this.sendingSucceeded)
          .fail(this.sendingFailed)
      })
      .on('hidden.bs.modal', () => {
        window.$('#sendingDoneModal').modal('show')
      })
  }

  render() {
    const form = this.props.view.data.form

    return (
      <div className="container order">
        <div className="row">
          <div className="col-md">

            <form onSubmit={this.startSending}>
              <div className="form-group">
                <h2>{form.title}</h2>

                <SelectedProducts 
                  order={OrderManager.order} 
                  totalPrice={OrderManager.totalPrice}
                  totalPriceLabel={form.totalPriceLabel} 
                  onSelectProduct={this.props.viewProduct}
                  onRemoveProduct={this.removeProduct} />

                <ProductAndSizeSelect 
                  ref={(me) => this._productSelector = me} 
                  show={this.state.order.products.length <= 0} 
                  productLists={ProductManager.productLists} 
                  showLabel={form.toggleAddProductFormLabel} 
                  productLabel={form.productLabel} 
                  addProductLabel={form.addProductLabel} 
                  onSelect={this.props.onSelectedProductsUpdate} />

              </div>
              
              <LocationSelect 
                className="form-group clearfix" 
                title={form.pickupLocationLabel}
                locations={form.pickupLocations} 
                mapLinks={this.props.shared.mapLinks} 
                value={this.state.order.pickupLocation} 
                onChange={this.handleInputChange} />

              <div className="form-group">
                <h4>{form.pickupSectionLabel}</h4>
                <input
                  required type="text"
                  className="form-control mb-2"
                  name="pickupTime"
                  placeholder={form.pickupTimeLabel}
                  value={this.state.order.pickupTime}
                  onChange={this.handleInputChange}
                  onKeyPress={(e) => this.next(e, "notes")} />
                <textarea
                  className="form-control mb-2"
                  name="notes"
                  rows="5"
                  placeholder={form.notesLabel}
                  value={this.state.order.notes}
                  onChange={this.handleInputChange}
                  onKeyPress={(e) => this.next(e, "customer.name")}></textarea>
              </div>

              <div className="form-group">
                <h4>{form.contactSectionLabel}</h4>
                <input
                  required
                  type="text"
                  className="form-control mb-2"
                  name="customer.name"
                  placeholder={form.nameLabel}
                  value={this.state.order.customer.name}
                  onChange={this.handleInputChange}
                  onKeyPress={(e) => this.next(e, "customer.email")} />
                <input
                  required
                  type="email"
                  className="form-control mb-2"
                  name="customer.email"
                  placeholder={form.emailLabel}
                  value={this.state.order.customer.email}
                  onChange={this.handleInputChange}
                  onKeyPress={(e) => this.next(e, "customer.phone")} />
                <input
                  type="text"
                  className="form-control mb-2"
                  name="customer.phone"
                  placeholder={form.phoneLabel}
                  value={this.state.order.customer.phone}
                  onChange={this.handleInputChange} />
              </div>

              <button
                className="btn btn-primary px-5"
                name="orderSubmit">
                  {form.orderLabel}
                </button>
            </form>

            <Divider mobileOnly={true} />
          </div>
          <div className="col">
            {this.props.view.data.content.map((block, index) => 
              <div key={index}>
                <h2>{block.title}</h2>
                {block.body.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <Divider />

        <WaitModal id="waitModal" />
        <Modal id="sendingDoneModal" 
          data={this.state.dialogData} 
          closeLabel={this.props.content.texts.closeLabel} />
      </div>
    )
  }
}

export default OrderView