import React, { Component } from 'react'
import icon_sheet_src from '../data/icons.svg'

class Icon extends Component {
  render() {
    return (
      <svg
        alt={this.props.alt ? this.props.alt : ''}
        className={`icon icon-${this.props.icon} ${this.props.className ? this.props.className : ''}`}>
        <use xlinkHref={`${icon_sheet_src}#${this.props.icon}`} />
      </svg>
    )
  }
}

export default Icon