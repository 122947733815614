import React from 'react'

import Icon from '../icons/Icon'
import ProductManager from '../ProductManager'

class Ingredient extends React.Component {
  render() {
    return (
      <div className="ingredient">
        <a href={`#${ProductManager.pidKey}${this.props.product.id}`}>
          <Icon icon="info" />&nbsp;&nbsp;{this.props.label}
        </a>
        <div className="ingredient-list">
          {ProductManager.buildIngredientList(this.props.product)}
        </div>
      </div>
    )
  }
}

export default Ingredient