import React, { Component } from 'react'

import ProductManager from '../ProductManager'

import Divider from '../icons/Divider'
import ExpandableImage from '../components/ExpandableImage'
import Ingredient from '../components/Ingredient'
import Price from '../components/Price'

class ProductView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: null
    }
    this.clearExpanded = this.clearExpanded.bind(this)
    this.toggleExpanded = this.toggleExpanded.bind(this)
    this.trackScroll = this.trackScroll.bind(this)
  }

  scrollToProduct(productId) {
    const targetTop = window.$(`[data-pid=${productId}]`)[0].offsetTop,
          navHeight = document.getElementsByClassName('sticky-top')[0].clientHeight
    window.scrollTo(0, targetTop - navHeight - 10)
  }

  clearExpanded(e) {
    if (e.path && e.path.indexOf(this.element) <= 0) {
      this.toggleExpanded(null)
    }
  }

  toggleExpanded(product) { 
    this.setState({ expanded: (this.state.expanded === product) ? null : product })
  }

  trackScroll() {
    try {
      let productElements = document.getElementsByClassName('product card'),
          navHeight = document.getElementsByClassName('sticky-top')[0].clientHeight,
          scrollY = window.scrollY ? window.scrollY : window.pageYOffset,
          viewPortTop = scrollY + navHeight,
          viewPortBottom = scrollY + (window.innerHeight / 3)

      for (let i = 0; i < productElements.length; i++) {
        const top = productElements[i].offsetTop
        if ( viewPortTop < top && top < viewPortBottom ) {
          const hash = '#' + ProductManager.pidKey + productElements[i].getAttribute('data-pid')
          if (window.location.hash === hash) return
          if ('pushState' in window.history) window.history.replaceState('', document.title, window.location.pathname + hash)
          else window.location.hash = hash
        }
      }
    }
    catch (e) {
      console.warn(e);
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScroll)
    if (this.props.focus) {
      this.setState({ expanded: this.props.focus })
      window.setTimeout(this.scrollToProduct, 200, this.props.focus.id)
    }
    else {
      this.setState({ expanded: null })
    }
    document.body.addEventListener('click', this.clearExpanded)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clearExpanded)
  }

  render() {    
    return (
      <div ref={(me) => this.element = me} className={`container products ${this.props.className ? this.props.className : ''}`}>
        <h1>{this.props.view.title}</h1>
        {this.props.view.data 
          && this.props.view.data.body 
          && this.props.view.data.body.map((paragraph, index) =>
          <p key={index}>{paragraph}</p>
        )}
        <div className="card-columns">
          {this.props.view.products.map((product) => 
            <div
              key={product.id}
              className={`product card ${this.state.expanded === product ? 'expanded' : ''}`}
              data-pid={product.id.toString()}
              data-name={product.name}>
              <ExpandableImage 
                image={ProductManager.findImage(product)}
                onClick={_ => this.toggleExpanded(product)}
                className="card-img-top" />
              <div className="card-body" onClick={_ => this.toggleExpanded(product)}>
                <h2 className="card-title" dangerouslySetInnerHTML={{ __html: product.name}}></h2>
                <h4 className="card-subtitle mb-2 text-muted">{product.allergens}</h4>
                {product.description && 
                  <p className="card-text">{product.description}</p>
                }
                <div className="card-subtitle mb-2">
                  <Ingredient 
                    product={product}
                    label={this.props.content.texts.ingredientLabel} />
                </div>
              </div>
              <div className="prices">
                {ProductManager.findPriceSet(product).map((price) => 
                  <Price 
                    key={price.size} 
                    price={price} 
                    product={product.id} 
                    sizes={this.props.content.sizes} 
                    addProductLabel={this.props.content.texts.addProductLabel} 
                    onSelectedProductsUpdate={this.props.onSelectedProductsUpdate} />
                )}
              </div>
              <Divider />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProductView