import React, { Component } from 'react'

import Icon from './Icon'

const COUNT_DISPLAY_LIMIT = 4

class MultipleIcons extends Component {
  render() {
    var icons = [],
        step = (this.props.step && this.props.count >= COUNT_DISPLAY_LIMIT) ? this.props.step : 1
        
    for (var i = 0; i < this.props.count; i += step) {
      icons.push(<Icon key={i} icon={this.props.icon} />)
    }
    return (
      <div className={`icon-multiple nr-${this.props.count}`}>
        {icons}
        {(this.props.count >= COUNT_DISPLAY_LIMIT) &&
          <span className="count">{this.props.count}</span>
        }
      </div>
    )
  }
}

export default MultipleIcons