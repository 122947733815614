import React from 'react'

import ProductManager from '../ProductManager'

import Icon from '../icons/Icon'

class SelectedProducts extends React.Component {
  constructor(props) {
    super(props)
    this.rows = []
    this.remove = this.remove.bind(this)
  }
  remove(selected, index) {
    if (! this.rows[index]) return
    if (this.rows[index].classList.contains('vanish')) return

    this.rows[index].classList.add('vanish')
    window.setTimeout(() => {
      this.rows[index].classList.remove('vanish')
      this.rows.splice(index)
      this.props.onRemoveProduct(selected)
    }, 300)
  }
  render() {
    let products = this.props.order.products.map((selected) => {
        return {
          product: ProductManager.findProduct(selected.product), 
          price: selected.price,
          size: selected.size
        }
      })

    return (
      <div className="product-list container">
        {products.map((selected, index) => 
          <div ref={(me) => this.rows[index] = me} key={index} className="mb-2 row align-items-start">
            <div className="col-3 image" onClick={(e) => this.props.onSelectProduct(selected.product)}>
              <img src={ProductManager.findImage(selected.product)} alt="" />
              <Icon icon="info" />
            </div>
            <div className="col">
              <div dangerouslySetInnerHTML={{ __html: selected.product.name}}></div>
              <div className="sub-text">{ProductManager.findSizeDescription(selected.size)}</div>
            </div>
            <div className="col-3 price">{selected.price.price.toLocaleString()} €
              <button type="button" className="close px-2 link-button" onClick={(e) => this.remove(selected, index)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        )}
        {this.props.totalPrice > 0 &&
          <div className="row">
            <div className="col price total">{this.props.totalPriceLabel}: {this.props.totalPrice.toLocaleString()} €</div>
          </div>
        }
      </div>
    )
  }
}

export default SelectedProducts