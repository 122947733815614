import React from 'react'

class Modal extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">{this.props.data.title}</h5>
            </div>
            <div className="modal-body">
              {this.props.data.body && this.props.data.body.map((paragraph, index) =>
                <p key={index} className={this.props.data.centerBody ? 'text-center' : ''}>{paragraph}</p>
              )}
              {this.props.data.dynamicBody && 
                <div className="card">
                  <div className="card-body">
                    {this.props.data.dynamicBody.map((paragraph, index) =>
                      <div key={index} dangerouslySetInnerHTML={ paragraph.html && {__html: paragraph.html} }>{paragraph.text}</div>
                    )}
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary mx-auto px-5" data-dismiss="modal">{this.props.closeLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal