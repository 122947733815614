import React from 'react'

import Icon from '../icons/Icon'

class Alert extends React.Component {
  constructor(props) {
    super(props)
    this.action = this.action.bind(this)
  }
  action(e) {
    if (! this.props.data.action) return
    const viewId = this.props.data.action.openView
    if (viewId) {
      this.props.changeView(viewId)
    }
  }
  render() {
    return (
      <div
        className={`alert alert-primary ${this.props.data.action ? 'action' : ''}`}
        role="alert"
        onClick={this.action}>
        <Icon icon={this.props.data.icon} />
        <h5>{this.props.data.title}</h5>
        {this.props.data.body.map((paragraph, index) => 
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        )}
      </div>
    )
  }
}

export default Alert