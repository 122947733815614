import React, { Component } from 'react'

import OrderButton from './OrderButton'
import Logo from './Logo'

class NavBar extends Component {
  render() {
    const orderView = this.props.content.views.filter(v => v.id === 20)[0],
          topNavViews = this.props.content.views.filter(v => v.id > 1 && v.id < 20);

    return (
      <div className="sticky-top">
        <nav className="container navbar navbar-expand-md navbar-light">
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false">
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
          <span data-toggle="collapse" data-target=".navbar-collapse.show">
            <Logo 
              className="navbar-brand" 
              onClick={_ => this.props.onChangeView(this.props.mainView)} />
          </span>
          <span className="nav-item order mobile" data-toggle="collapse" data-target=".navbar-collapse.show">
            { (this.props.nrOfSelectedProducts > 0 && this.props.view !== orderView) &&  
              <OrderButton
                title={orderView.title}
                selectedCount={this.props.nrOfSelectedProducts}
                onClick={_ => this.props.onChangeView(orderView)} />
            }
          </span>

          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav mx-auto">
              {topNavViews.map((view, index) => 
                <li key={index} className={`nav-item ${this.props.view === view ? "active" : ""}`} data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a role="button" className="nav-link" onClick={(e) => this.props.onChangeView(view)}>
                    {view.title}
                  </a>
                </li>
              )}
              <li className={`nav-item order mobile ${this.props.view === orderView ? "active" : ""}`} data-toggle="collapse" data-target=".navbar-collapse.show">
                <a role="button" className="nav-link" onClick={(e) => this.props.onChangeView(orderView)}>
                  {orderView.title}
                </a>
              </li>
            </ul>
            <ul className="navbar-nav my-2 my-lg-0">
              <li>
                <div className="btn-group btn-group-toggle language-switch" data-toggle="language">
                  <label className={`btn btn-secondary ${this.props.language === "fi" ? "active" : ""}`} >
                    <input type="radio" name="language" id="fi" autoComplete="off" checked={this.props.language === "fi"} onChange={(e) => this.props.onSwitchLanguage('fi')} /> FI
                  </label>
                  <label className={`btn btn-secondary ${this.props.language === "sv" ? "active" : ""}`} >
                    <input type="radio" name="language" id="sv" autoComplete="off" checked={this.props.language === "sv"} onChange={(e) => this.props.onSwitchLanguage('sv')} /> SV
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <span className="nav-item order desktop">
            <OrderButton
              title={orderView.title}
              selectedCount={this.props.nrOfSelectedProducts}
              onClick={(e) => this.props.onChangeView(orderView)} />
          </span>
        </nav>
      </div>
    )
  }
}

export default NavBar