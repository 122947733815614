import React, { Component } from 'react'

class Version extends Component {
  _version = require('../../package.json').version

  get version() {
    return (this.props.view.title === 'Bakgrund')
            ? 'Älskar dej Bettina <3'
            : this._version
  }

  render() {
    return ( <div className="version">{this.version}</div> )
  }
}

export default Version