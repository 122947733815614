import ProductManager from './ProductManager'

class OrderManager {
  _key = 'order'

  constructor() {
    if (! OrderManager.instance) {
      this.fromCache()
      OrderManager.instance = this
    }
 
    return OrderManager.instance
  }

  get order() {
    return this._order
  }

  get totalPrice() {
    let totalPrice = 0
    this._order.products.forEach((p) => totalPrice += p.price.price)
    if (this._order.pickupLocation) {
      totalPrice += this._order.pickupLocation.cost
    }
    return totalPrice
  }

  addProduct(productId, sizeId) {
    if (!productId || productId <= 0 || !sizeId || sizeId.length <= 0) return []
    const product = ProductManager.findProduct(productId)
    if (!product) return []

    const priceSet = ProductManager.findPriceSet(product),
          selected = {
            product: product.id,
            size: sizeId,
            price: priceSet.filter((p) => p.size === sizeId)[0]
          }

    this._order.products.push(selected)

    this.toCache()

    return [ selected ]
  }

  removeProduct(selected) {
    const selectedProduct = this._order.products.filter((p) => p.product === selected.product.id && p.size === selected.size)[0]
    if (! selectedProduct) throw(new Error('Selected product not found anymore!'))
    this._order.products.splice(this._order.products.indexOf(selectedProduct), 1)
    
    this.toCache()
  }

  clear() {
    this._order.products = []
    this._order.pickupLocation = null
    this._order.pickupTime = ''
    this._order.notes = ''
    this.toCache()
  }

  fromCache() {
    const cached = JSON.parse(window.localStorage.getItem(this._key))
    if (cached) {
      this._order = cached
    }
    else {
      this._order = {};
      this.clear()
    }
    
    if (! this._order.customer) {
      this._order.customer = {
        name: '',
        email: '',
        phone: '',
      }
    }
  }

  toCache() {
    window.localStorage.setItem(this._key, JSON.stringify(this._order))
  }
  
  html2text(html) {
    var d = document.createElement("div")
    d.innerHTML = html
    return d.textContent
  }

  send(template, texts) {
    var message = {
      "template": template,
      "productList": this.productsAsHtml(texts),
      "total": `${this.totalPrice} €`,
      "pickupLocation": this.order.pickupLocation.name,
      "pickupTime": this.order.pickupTime,
      "notes": this.order.notes,
      "customer": this.order.customer
    }
    console.log(message)
    return window.$.ajax(
      "https://minkakafunctions.azurewebsites.net/api/SendOrder?code=LMoC0kEbmEjYOlejcGEckk6aLafa8QdfaaQfsFnOjILg9g9QVoQd5Q==", {
      type: "POST",
      data: JSON.stringify(message),
      contentType: "application/json"
    })
  }

  productsAsHtml(texts) {
    let html = this._order.products
      .map((selected) => (`${ProductManager.findProduct(selected.product).name}, ${ProductManager.findSizeDescription(selected.size)}: ${selected.price.price} €`))
      .join("<br/>\n")

      if ( this._order.pickupLocation && this._order.pickupLocation.cost > 0) {
        html += `<br/>\n${texts.pickupFeeLabel}: ${this._order.pickupLocation.cost} €`
      }
    return html
  }
  
  orderAsHtmlRows(texts) {
    let rows = []
    rows.push( {html: `<h3>${texts.title}</h3>`} )
    rows.push( {html: this.productsAsHtml(texts)} )
    rows.push( {html: `<b>${texts.totalPriceLabel}: ${this.totalPrice} €</b>`} )

    rows.push( {html: `<h3>${texts.pickupSectionLabel}</h3>`} )
    rows.push( {text: this._order.pickupLocation.name} )
    rows.push( {text: this._order.pickupTime} )

    if (this._order.notes && this._order.notes.length > 0) {
      rows.push( {html: `<h4>${texts.notesLabel}</h4>`} )
      rows.push( {text: this._order.notes} )
    }

    rows.push( {html: `<h3>${texts.contactSectionLabel}</h3>`} )
    rows.push( {text: this._order.customer.name} )
    rows.push( {text: this._order.customer.email} )
    rows.push( {text: this._order.customer.phone} )
    
    return rows
  }
}

const instance = new OrderManager()
Object.freeze(instance)

export default instance