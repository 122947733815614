import React, { Component } from 'react'

import content from './data/content'

import OrderManager from './OrderManager'
import ProductManager from './ProductManager'

import Contact from './components/Contact'
import Logo from './components/Logo'
import NavBar from './components/NavBar'
import SideTab from './components/SideTab'
import Version from './components/Version'
import ViewController from './views/ViewController'

import icon_sheet_src from './data/icons.svg'

class App extends Component {
  _key = 'language'

  constructor(props) {
    super(props)

    this.changeView = this.changeView.bind(this)
    this.changeViewById = this.changeViewById.bind(this)
    this.changeViewByProduct = this.changeViewByProduct.bind(this)
    this.getLocalizedContent = this.getLocalizedContent.bind(this)
    this.initLanguage = this.initLanguage.bind(this)
    this.updateSelectedProductCount = this.updateSelectedProductCount.bind(this)

    this.preloadImages()

    const localized = this.getLocalizedContent(window.localStorage.getItem(this._key)),
          mainView = localized.content.views.filter(v => v.id === 1)[0]
    this.state = {
      view: mainView,
      content: localized.content,
      language: localized.language,
      nrOfSelectedProducts: OrderManager.order.products.length,
      mainView: mainView,
    }
    ProductManager.init(localized.content)

    let product = ProductManager.findProductWithHash(window.location.hash)
    let view = ProductManager.findViewForProduct(product, localized.content.views)
    if (view) {
      this.state.view = view
      this.state.focus = product
    }
  }

  changeView(view) {
    window.scrollTo(0,0)
    if ('replaceState' in window.history) {
      window.history.replaceState('', document.title, window.location.pathname)
    }
    else {
      window.location.hash = ''
    }
    this.setState( { view: view, focus: null })
  }

  changeViewById(id) {
    const view = this.state.content.views.filter(v => v.id === id)
    if (view.length > 0) this.changeView(view[0])
  }

  changeViewByProduct(product) {
    let view = ProductManager.findViewForProduct(product, this.state.content.views)
    this.setState( { view: view, focus: product })
  }

  getLocalizedContent(language) {
    var localized = { content: null, language: language }
    if (localized.language === 'sv') {
      localized.content = content.sv
    }
    else {
      localized.content = content.fi
      localized.language = 'fi'
    }
    return localized
  }

  initLanguage(language) {
    const localized = this.getLocalizedContent(language)
    ProductManager.init(localized.content)
    const view = localized.content.views.filter((v) => v.id === this.state.view.id)[0],
          mainView = localized.content.views.filter(v => v.id === 1)[0]

    this.setState( {
      view: view ? view : mainView,
      content: localized.content,
      language: localized.language,
      mainView: mainView
    } )

    window.localStorage.setItem(this._key, language)
  }

  preloadImages() {
    this._images = []
    
    preload([icon_sheet_src], this._images)
    content.shared.imageSets.forEach((imageSet) => {
      preload([imageSet.mainImage], this._images)
      preload(imageSet.secondaryImage, this._images)
    })
    preload(content.shared.productImages, this._images)

    function preload(urls, preloadList) {
      urls.forEach((url) => {
        preloadList.push(new Image())
        preloadList[preloadList.length - 1].src = url
      })
    }
  }

  updateSelectedProductCount() {
    this.setState( {
      nrOfSelectedProducts: OrderManager.order.products.length
    } )
  }

  render() {
    return (
      <div>
        <Logo onClick={_ => this.changeView(this.state.mainView)} />
        <NavBar 
          onChangeView={this.changeView}
          onSwitchLanguage={this.initLanguage} {...this.state} />
        <ViewController
          onSelectedProductsUpdate={this.updateSelectedProductCount}
          viewProduct={this.changeViewByProduct}
          changeView={this.changeViewById}
          shared={content.shared}
          {...this.state} />
        <Contact contact={this.state.content.contact} />
        <Version view={this.state.view} />
      </div>
    )
  }
}

export default App