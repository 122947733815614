import React from 'react'

import content from './data/content'

import ProductManager from './ProductManager'

class ProductDataTable extends React.Component {

  PricesOnly() {
    return (
      <div className="price-list">
        {ProductManager.productLists.map((productList) =>
          <div>
            <h2>{productList.title}</h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Tuotenumero</th>
                  <th scope="col">Nimi</th>
                  <th scope="col">Yksikkö</th>
                  <th scope="col">ALV %</th>
                  <th scope="col">Hinta verottomana</th>
                </tr>
              </thead>
              <tbody>
                {productList.products.map((product) =>
                  ProductManager.findPriceSet(product).map((price) =>
                    <tr>
                      <td>{product.id}</td>
                      <td dangerouslySetInnerHTML={{ __html: product.name }}></td>
                      <td>{ProductManager.findSizeDescription(price.size)}</td>
                      <td>14 %</td>
                      <td>{(price.price / 1.14).toLocaleString()} €</td>
                    </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* <h2>{ProductManager.other.title}</h2>
        <table class="table">
          <tbody>
            {ProductManager.other.products.map((product) =>
              <tr>
                <td dangerouslySetInnerHTML={{ __html: product.name }}></td>
                <td>
                  {ProductManager.findPriceSet(product).map((price) =>
                    <div>
                      {ProductManager.findSizeDescription(price.size)} {price.price.toLocaleString()} €
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <h2>{ProductManager.seasonal.title}</h2>
        <table class="table">
          <tbody>
            {ProductManager.seasonal.products.map((product) =>
              <tr>
                <td dangerouslySetInnerHTML={{ __html: product.name }}></td>
                <td>
                  {ProductManager.findPriceSet(product).map((price) =>
                    <div>
                      {ProductManager.findSizeDescription(price.size)} {price.price.toLocaleString()} €
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table> */}
      </div>
    )
  }

  FullList() {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Nimi</th>
            <th scope="col">Allergeenit</th>
            <th scope="col">Kuvaus</th>
            <th scope="col">Koot ja hinnat</th>
            <th scope="col">Tuoteseloste</th>
            <th scope="col">Kuva</th>
          </tr>
        </thead>
        <tbody>
          {ProductManager.cakes.products.map((product) =>
            <tr>
              <th scope="row"><a href={`/#${ProductManager.pidKey}${product.id}`}>http://www.minkaka.fi/#{ProductManager.pidKey}{product.id}</a></th>
              <td dangerouslySetInnerHTML={{ __html: product.name }}></td>
              <td>{product.allergens}</td>
              <td>{product.description}</td>
              <td>
                {ProductManager.findPriceSet(product).map((price) =>
                  <React.Fragment>
                    {ProductManager.findSizeDescription(price.size)} {price.price.toLocaleString()} €
                  </React.Fragment>
                )}
              </td>
              <td>{ProductManager.buildIngredientList(product)}</td>
              <td><img src={ProductManager.findImage(product)} alt=''/></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  AccountorCSV() {
    /*
    "Tuotenumero", "Nimi", "Yksikkö", "ALV %", "Hinta verottomana"
    "1","Product 1","kpl","24","10.0000"
    "2","Product 2","h","24","20.0000"
    */
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Tuotenumero</th>
            <th scope="col">Nimi</th>
            <th scope="col">Yksikkö</th>
            <th scope="col">ALV %</th>
            <th scope="col">Hinta verottomana</th>
          </tr>
        </thead>
        <tbody>
          {ProductManager.cakes.products.map((product) =>
            <tr>
              <th scope="row">{product.id}</th>
              <td dangerouslySetInnerHTML={{ __html: product.name }}></td>
              <td>1 kpl</td>
              <td>14 %</td>
              <td>
                {ProductManager.findPriceSet(product).map((price) =>
                  <React.Fragment>
                    {ProductManager.findSizeDescription(price.size)} {(price.price / 1.24).toLocaleString()} €
                  </React.Fragment>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }
  
  render() {
    if (this.props.language && this.props.language === 'sv') {
      ProductManager.init(content.sv)
    }
    else {
      ProductManager.init(content.fi)
    }

    console.log(ProductManager)
    
    return (
      <this.PricesOnly />
    )
  }
}

export default ProductDataTable